//  icons
import {
  FiLinkedin,
  FiGithub,
  FiMail,
} from 'react-icons/fi';

// projects images
import CryptoWatch from './assets/CryptoWatch.webp';
import Project2 from './assets/img/projects/p2.webp';

// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg5 from './assets/img/skills/nextjs.png';
import SkillImg6 from './assets/img/skills/nodejs.png';
import SkillImg7 from './assets/img/skills/git.png';
import SkillImg8 from './assets/img/skills/figma.png';

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/in/nabil-abu-hadba',
  },
  {
    icon: <FiGithub />,
    href: 'https://www.github.com/nabuhadba',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: CryptoWatch,
    name: 'CryptoWatch',
    url: 'https://cryptowatch-personal.netlify.app/',
    description: 'Crypto currency watchlist site complete with Firebase Auth and CRUD Operations. Built using React, Firebase, and Tailwind CSS'
  },
  {
    id: '2',
    image: Project2,
    name: 'More Items to come',
    description: 'More projects are on the way!!'
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have a question?',
    subtitle: 'I am here to help you.',
    description: 'Email me at nabilabuhadba@gmail.com',
  },
];
