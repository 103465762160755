import React from 'react'
import {
    FaLinkedin,
    FaGithub,
} from 'react-icons/fa';

import Bitmoji from '../assets/bitmoji.png'

const Card = () => {
    return (
        <div className='flex flex-col justify-center max-w-xs mx-auto bg-secondary shadow-2xl rounded-xl p-5'>
            <div className="">
                <img className="w-32 mx-auto shadow-xl rounded-full drop-shadow-sm" src={Bitmoji} alt="profile face" />

            </div>
            <div className="text-center mt-5">
                <p className='text-xl sm:text-2xl font-semibold text-[white]'>
                    Nabil Abu-Hadba
                </p>
                <p className='text-xs sm:text-base text-[white] pt-2 pb-4 px-5 w-auto inline-block border-b-2'>
                    Web Developer / Software Engineer

                </p>
                <div className="flex align-center justify-center mt-4">
                    <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-white hover:bg-accent rounded-full hover:text-white transition-colors duration-300" href="https://github.com/nabuhadba">
                        <FaGithub />
                        <span class="sr-only">Github</span>
                    </a>
                    <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-white hover:bg-accent rounded-full hover:text-white transition-colors duration-300" href="https://linkedin.com/in/nabil-abu-hadba">
                        <FaLinkedin />
                        <span class="sr-only">LinkedIn</span>
                    </a>

                </div>
            </div>
        </div>
    )
}

export default Card