import React from 'react';

import { Link } from 'react-scroll';

const About = () => {
  return (
    <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Nabil Abu-Hadba
              </h2>
              <p className='mb-4 text-accent text-xl'>
                Web Developer and Software Engineer
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8 text-xl'>
                I am a passionate software engineer with and emphasis on Web Development with the ability to learn and
                collaborate in rapidly changing environments and compositions.
                Worked on multiple projects and have learned technologies like React.js, Node.js, HTML5, CSS3, Java, Python,
                and many more. Eager to tackle web development challenges to achieve lasting impacts on user experience. <br />

              </p>
            </div>
            <Link to='contact'
              spy={true}
              smooth={true}
              duration={500}
              offset={40}
              className='transition-all duration-300'>
              <button className='btn btn-md text-lg bg-accent hover:bg-secondary-hover transition-all'>
                Contact Me
              </button>
            </Link>

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
