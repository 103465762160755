import React from 'react';
import Resume from '../assets/Resume.pdf'
import Typewriter from "typewriter-effect";
import Card from './Card';


const Hero = () => {
  return (
    <section
      id='home'
      className='lg:h-[85vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'
    >
      <div className='container mx-auto h-full'>
        <div className='flex items-center h-full pt-8'>
          <div className='flex-1 flex flex-col items-center lg:items-start'>
            <p className='text-lg text-accent text-md mb-[22px]'>
              Hey, I'm Nabil! 👋
            </p>
            <h1 className='text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]'>
              I'm a <span className='text-accent'><Typewriter
                options={{
                  strings: ['Web Developer', 'Software Engineer', 'Product Designer'],
                  autoStart: true,
                  loop: true,
                }}
              /></span>
            </h1>
            <p className='pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-xl text-[#ffffff] text-center lg:text-left'>
              I’m a full-stack developer specializing in designing and buidling exceptional digital experiences.
              Currently, I’m focused on building responsive full-stack web applications.
            </p>
            <a href={Resume} target='_blank' rel='noreferrer' download>
              <button className='btn btn-md text-lg bg-accent hover:bg-secondary-hover md:btn-lg transition-all'>
                Download Resume
              </button>
            </a>

          </div>
          <div className='hidden lg:flex flex-1 justify-end items-center h-full'>
            <Card />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
